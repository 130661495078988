import { Typography } from "@mui/material";

export enum BlockRequestStateEnum {
  success = "success",
  error = "error",
  loading = "loading",
}

export const getModalMessageDownloadInvoiceFailure = (errorData?: any) => {
  return {
    component: (
      <Typography color="textPrimary" align="center" id="cardpaymentDeclineTypo">
        We are currently unable to retrieve this invoice, please try again later. <br />
        {errorData?.errorCode ? (
          <>
            <small>Code - {errorData?.errorCode}</small>
          </>
        ) : (
          <></>
        )}
      </Typography>
    ),
  };
};
