export enum PAYMENT_PROVIDER {
  MONEYHUB = "Moneyhub",
  MAVERICK = "Maverick",
}

export enum ERP_ID {
  XERO = "xero",
  SAGE = "sage",
  QUICKBOOKS = "quickbooks",
  INTERNAL = "internal",
}

export enum ATTACHEMENTS {
  RECEIVABLE_PDF = "Receivable PDF",
}

export enum SchemeName {
  IBAN = "IBAN",
  SortCodeAccountNumber = "SortCodeAccountNumber",
}

export enum PaymentType {
  Immediate = "Immediate",
}

export enum VATRate {
  ECServicesZeroRated = "ECServicesZeroRated",
  ReverseChargeServices = "ReverseChargeServices",
  ReverseCharge = "ReverseCharge",
  ECGoodsZeroRated = "ECGoodsZeroRated",
  Standard = "Standard",
  ReverseChargePhonesAndComputerChips = "ReverseChargePhonesAndComputerChips",
  ExemptFromVAT = "ExemptFromVAT",
  ECGoodsStandard = "ECGoodsStandard",
  ZeroRated = "ZeroRated",
  ECServicesStandard = "ECServicesStandard",
  Reduced = "Reduced",
  NoVATApplicable = "NoVATApplicable",
}

export enum InvoiceType {
  Payable = "Payable",
  Expense = "Expense",
  Receivable = "Receivable",
  CreditNote = "CreditNote",
}

export enum ReconciliationStatus {
  Reconciled = "Reconciled",
  NotReconciled = "NotReconciled",
}

export enum RTPStatus {
  RequestMade = "RequestMade",
  PaymentSent = "PaymentSent",
  PaymentReceived = "PaymentReceived",
  Declined = "Declined",
  Created = "Created",
  Scheduled = "Scheduled",
  Cancelled = "Cancelled",
  InProgress = "InProgress",
  Deleted = "Deleted",
}

export interface Amount {
  amount: string;
  currency: string;
}

export interface SenderDataResponse {
  id: string;
  name: string;
  companyInfo: {
    id: string;
    name: string;
    email: string;
    telephone: string;
    address: {
      lines: [string];
      postcode: string;
      city: string;
      country: string;
    };
  };
  primaryContact: {
    id: string;
    name: string;
    email: string;
    telephone: string;
    address: {
      lines: [string];
      postcode: string;
      city: string;
      country: string;
    };
  };
  otherContacts: [
    {
      id: string;
      name: string;
      email: string;
      telephone: string;
      address: {
        lines: [string];
        postcode: string;
        city: string;
        country: string;
      };
    },
  ];
  taxInfo: {
    vatRegistrationNumber?: string;
    utrNumber: string;
    defaultVatRate: string;
    defaultCurrency: string;
  };
  SIC: [string];
  logo: string;
  businessContact?: BusinessLevelContact;
}

export type BusinessLevelContact = {
  name: string;
  email: string;
  telephone: string;
};

export type BusinessLevelContactStatus = {
  email: string;
  verified: boolean;
};

export type Address = {
  addressLines?: [string];
  postcode?: string;
  city?: string;
  countryId?: string;
};

export type ContactId = {
  source?: string;
  externalId: string;
};

export type BankDetails = {
  identification: string;
  schemeName: string;
};

export interface Contact {
  id: ContactId;
  name: string;
  email?: string;
  billingAddress?: Address;
  vatRate: VATRate;
  mobile: string;
  bankDetails?: BankDetails;
}

export enum RTPDeliveryChannel {
  "Email" = "Email",
  "Sms" = "Sms",
  "OwnSms" = "OwnSms",
  "FaceToFace" = "FaceToFace",
  "WhatsApp" = "WhatsApp",
}

export type RTP = {
  amount: Amount;
  channel: RTPDeliveryChannel;
  customer: Contact;
  details: {
    externalId: string;
    source: string;
  };
  id: string;
  scheduledDates?: string[];
  status: RTPStatus;
  supplier: {
    SIC: string[];
    companyInfo: {
      email: string;
      id: string;
      name: string;
      telephone: string;
    };
    name: string;
    otherContacts: [];
    primaryContact: {
      id: string;
      name: string;
      email?: string;
      telephone?: string;
    };
    taxInfo: {
      defaultCurrency: string;
      defaultVatRate: string;
      utrNumber: string;
      vatRegistrationNumber?: string;
    };
    businessContact?: BusinessLevelContact;
  };
  supplierBankDetails: {
    accountNumber: string;
    scheme: string;
    bankId: string;
  };
  receivable: {
    lineItems: Receivable["lineItems"];
    dueDateTime: Receivable["dueDateTime"];
    dateTimeIssued: Receivable["dateTimeIssued"];
    reference: Receivable["reference"];
    customerEmail: string;
    customerMobile: string;
    features?: string[];
  };
  deliveryDates?: string[];
  reminders?: {
    channel: string;
    enabled: boolean;
    tpe: string;
  }[];
  standingOrder?: StandingOrderDetails;
  paymentLink?: string;
  cardPaymentProvider?: CardPaymentProviders;
};

enum CardPaymentProviders {
  SQUARE = "Square",
}

export interface InvoiceLineItem {
  id: string;
  amountTaxExclusive: Amount;
  category: string;
  description: string;
  vatRate: VATRate;
  ledgerCode?: number;
}

export type InvoiceStatus = "Draft" | "Approved" | "Void" | "Sent" | "Paid" | "PartiallyPaid";

export type Invoice = {
  entityDetails: {
    source: string;
    externalId: string;
  };
  lineItems: InvoiceLineItem[];
  dateTimeIssued: string;
  dueDateTime: string;
  reference: string;
  totalAmountTaxExclusive: Amount;
  totalAmountTaxInclusive: Amount;
  paidDate: string;
  plannedPaymentDate: string;
  status: InvoiceStatus;
  attachments?: [
    {
      id: string;
      name: string;
      uri: string;
      contentType: string;
      createdAt: string;
    },
  ];
  notes: string[];
  name: string;
  description: string;
};

export type Receivable = Invoice & {
  id: string;
  customerContact: Contact;
  invoiceType: InvoiceType.Receivable;
  remainingAmountTaxExclusive: Amount;
};

export interface RequestResponse {
  paymentRequestId: string;
  paymentDetails: {
    amount: Amount;
    creditAccount: {
      schemeName: SchemeName;
      identification: string;
      name: string;
      secondaryIdentification: string;
    };
    debtorAccount: {
      schemeName: SchemeName;
      identification: string;
      name: string;
      secondaryIdentification: string;
    };
    instructionIdentification: string;
    endToEndIdentification: string;
    remittanceInformation: {
      unstructured: string;
      reference: string;
    };
    executionDate: string;
    paymentType: PaymentType;
    additionalData: {
      initialInvoiceType?: string;
    };
  };
  payable: {
    entityDetails: {
      source: ERP_ID;
      externalId: string;
    };
    lineItems: InvoiceLineItem[];
    dateIssued: string;
    dueDate: string;
    supplierContact: {
      id: {
        source: string;
        externalId: string;
      };
      name: string;
      email: string;
      billingAddress: {
        addressLines: [string];
        postcode: string;
        city: string;
        countryId: string;
      };
      vatNumber: string;
      vatRate: VATRate;
      mobile: string;
    };
    debtorContact: {
      id: {
        source: string;
        externalId: string;
      };
      name: string;
      email: string;
      billingAddress: {
        addressLines: [string];
        postcode: string;
        city: string;
        countryId: string;
      };
      vatNumber: string;
      vatRate: VATRate;
      mobile: string;
    };
    reference: string;
    totalAmountTaxExclusive: Amount;
    totalAmountTaxInclusive: Amount;
    paidDate: string;
    plannedPaymentDate: string;
    status: InvoiceStatus;
    invoiceType: InvoiceType;
    reconciliationStatus: ReconciliationStatus;
    attachments: [
      {
        id: string;
        name: string;
        uri: string;
        contentType: string;
        createdAt: string;
      },
    ];
    notes: [string];
  };
}

export interface RTPUpdateResponse {
  details: {
    source: string;
    externalId: string;
  };
  customer: {
    name: string;
    email: string;
    mobile: string;
  };
  supplier: {
    name: string;
    companyInfo: {
      id: string;
      name: string;
      email: string;
      telephone: string;
    };
    primaryContact: {
      id: string;
      name: string;
      email: string;
      telephone: string;
    };
    otherContacts: {
      id: string;
      name: string;
      email: string;
      telephone: string;
    }[];
    taxInfo: {
      vatRegistrationNumber?: string;
      utrNumber: string;
      defaultVatRate: string;
      defaultCurrency: string;
    };
    SIC: string[];
    businessContact?: BusinessLevelContact;
  };
  supplierBankDetails: {
    scheme: string;
    accountNumber: string;
  };
  id: string;
  status: string;
  scheduledDates: string[];
  amount: Amount;
  channel: string;
  standingOrder?: StandingOrderDetails;
}

export interface BanksResponse {
  country: string;
  description: string;
  features: any[];
  fullName: string;
  id: string;
  logo: string;
  provider: string;
  shortName: string;
  tags: string[];
  type: string;
  website: string;
}

export interface PaymentInitiationResponse {
  redirectUrl: string;
  consentId: string;
  status: string;
  creationDateTime: string;
}

export enum PaymentStatus {
  Pending = "Pending",
  Received = "Received",
  Rejected = "Rejected",
  Accepted = "Accepted",
  Settled = "Settled",
  AwaitingAuthorisatio = "AwaitingAuthorisatio",
  AwaitingUpload = "AwaitingUpload",
  Authorised = "Authorised",
  Authorized = "Authorized",
  InitiationPending = "InitiationPending",
}

export interface PaymentStateResponse {
  consentId: string;
  context: {
    custodianId: string;
    customerId: string;
    status: PaymentStatus;
    created: string;
    consentType: string;
    externalId: string;
  };
  consentBody: string;
  meta: { key: string; value: any }[];
}

export interface SingleImmediatePaymentBody {
  amount: Amount;
  creditAccount: {
    schemeName: SchemeName;
    identification: string;
    name?: string;
    secondaryIdentification?: string;
  };
  debtorAccount?: {
    schemeName?: SchemeName;
    identification?: string;
    name?: string;
    secondaryIdentification?: string;
  };
  creditorDetails?: {
    paymentContextCode?: string;
    merchantCategoryCode?: string;
    merchantCustomerIdentification?: string;
    deliveryAddress?: {
      addressLines?: [string];
      postcode?: string;
      city?: string;
      countryId?: string;
    };
  };
  instructionIdentification?: string;
  endToEndIdentification?: string;
  remittanceInformation?: {
    unstructured?: string;
    reference?: string;
  };
  executionDate?: string;
  additionalData: {
    BankId: string;
    requestId: string;
    erpId: string;
    invoiceId: string;
    invoiceType: string;
    customerId: string;
    initialInvoiceType: string;
    orgId: string;
    features: string;
  };
  paymentType: PaymentType;
}

export interface StandingOrderPaymentBody {
  bankId: string;
  frequency: InstalmentFrequency;
  numberOfPayments?: string;
  firstPaymentAmount: Amount;
  firstPaymentDateTime: string;
  creditAccount: {
    schemeName: SchemeName;
    identification: string;
    name?: string;
    secondaryIdentification?: string;
  };
}

export interface ConfirmPaymentResponse {
  consentId: string;
  paymentId: string;
  status: PaymentStatus;
  payment: {
    amount: Amount;
    creditAccount: {
      schemeName: SchemeName;
      identification: string;
      name?: string;
      secondaryIdentification?: string;
    };
    debtorAccount?: {
      schemeName: SchemeName;
      identification: string;
      name: string;
      secondaryIdentification: string;
    };
    creditorDetails?: {
      paymentContextCode: string;
      merchantCategoryCode: string;
      merchantCustomerIdentification: string;
      deliveryAddress: {
        addressLines: [string];
        postcode: string;
        city: string;
        countryId: string;
      };
    };
    instructionIdentification?: string;
    endToEndIdentification?: string;
    remittanceInformation?: {
      unstructured: string;
      reference: string;
    };
    executionDate?: string;
    additionalData?: {};
    paymentType: PaymentType;
  };
  executionDate?: string;
}

export interface ConfirmStandingOrderResponse {
  consentId: string;
  paymentId: string;
  status: PaymentStatus;
  payment: StandingOrderPaymentBody;
  redirectUrl?: string;
}

export enum InstalmentFrequency {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}

export interface StandingOrderDetails {
  reference: string;
  frequency: InstalmentFrequency;
  numberOfPayments?: number;
  firstPaymentAmount: Amount;
  recurringPaymentAmount?: Amount;
  finalPaymentAmount?: Amount;
  firstPaymentDate?: string;
  recurringPaymentDate?: string;
  finalPaymentDate?: string;
}

// Maverick API
export interface MaverickPaymentPayload {
  rtpId: string;
  customerId: string;
  paymentDetails?: {
    paymentId?: number;
    status: string;
  };
  amount: Amount;
  entityDetails: Invoice["entityDetails"];
}

export interface MaverickHostedFormPayload {
  rtpId: string;
  customerId: string;
  redirectUrl: string;
  amount: Amount;
  entityDetails: Invoice["entityDetails"];
}

export interface MetroBankAccountDetails {
  accountNumber: string;
  customerName: string;
  sortCode: string;
  returnedCustomerName: string;
  resultText: string;
  nameMatchResult: string;
  accountTypeResult: boolean;
  result: boolean;
  customerId: string;
  status: MetroBankAccountStatuses;
}

export enum MetroBankAccountStatuses {
  enabled = "Enabled",
  disabled = "Disabled",
}

// Card Payments

export type CardPaymentPayload = {
  amount: Amount;
  customerId: string;
  rtpId: string;
  orgId?: string;
  invoiceDetails: {
    source: string;
    externalId: string;
  };
};
