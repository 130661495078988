import React from "react";
import { format } from "date-fns";
import { Box, Button, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import DownloadIcon from "@mui/icons-material/ArrowDownwardOutlined";

import { useAlert } from "@APP/hooks";
import { AppState } from "@APP/redux";
import { formatCurrency } from "@APP/utils";
import { ERP_ID, ATTACHEMENTS } from "@APP/types";
import { getModalCommonButton } from "@APP/views/DeclineRTPScreen/getModalContent";

import { getModalMessageDownloadInvoiceFailure } from "./getModalContent";

interface Props {
  rtpState: AppState["rtp"];
}

const RTPSummary: React.FC<Props> = ({ rtpState }) => {
  const { rtp, rtpData, senderData } = rtpState;
  const { alert } = useAlert();

  const { entityDetails, lineItems, reference } = rtpData?.payable || {};

  const isInternal = entityDetails?.source === ERP_ID.INTERNAL;
  const isNative = rtp?.receivable.features?.includes("Native");
  const invoiceLink = rtpData?.payable?.attachments.find(
    (item) => item.name === ATTACHEMENTS.RECEIVABLE_PDF && item.contentType === "application/pdf",
  )?.uri;

  async function downloadInvoice() {
    if (invoiceLink) {
      try {
        // Fetch the invoice
        const response = await fetch(invoiceLink, { method: "GET" });

        // Get the Content-Type header
        const contentType = response.headers.get("Content-Type");

        if (contentType === "application/pdf") {
          // Create a Blob and open it in a new tab
          const blob = await response.blob();
          const pdfUrl = URL.createObjectURL(blob);
          const newTab = window.open(pdfUrl, "_blank");
          // Add "noopener" and "noreferrer" for security
          if (newTab) {
            newTab.opener = null; // Equivalent to "noopener"
          }
        } else if (contentType === "application/json") {
          // Parse the JSON error response
          const errorData = await response.json();
          alert("", getModalMessageDownloadInvoiceFailure(errorData), getModalCommonButton());
        } else {
          throw new Error("Unexpected content type");
        }
      } catch (error) {
        alert("", getModalMessageDownloadInvoiceFailure(), getModalCommonButton());
      }
    }
  }

  // Download button should be displayed in case of external invoice or internal native invoice:
  const shouldDisplayDownloadLink = invoiceLink && (!isInternal || isNative);

  return (
    <Box textAlign="center" id="rtpSummeryBox">
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography id="rtpSummeryTypogrophy">
          <b>{senderData?.businessContact?.name ?? senderData?.name}</b> has requested
        </Typography>
      </Box>
      <Box my={0.5}>
        <Typography color="textSecondary" component="p" variant="h4" id="rtpSummeryAmount">
          {formatCurrency(rtpData?.paymentDetails.amount)}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography gutterBottom id="rtpSummeryInvoiceTypo">
          {isInternal ? "for" : "for invoice"}&nbsp;
        </Typography>
        <Typography component="p" variant="subtitle1" gutterBottom id="rtpSummeryRef">
          {isInternal ? lineItems?.[0].description : reference}
        </Typography>
      </Box>
      {rtp?.standingOrder && (
        <Box mt={2} mb={2} display="flex" flexDirection="column" alignItems="center">
          <Typography gutterBottom id="rtpSummeryPayment">
            Payment to be taken in the below instalments
          </Typography>
          <Box
            mt={1}
            maxWidth={320}
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center">
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom id="rtpSummeryPaymentLabel">
                Payment Amount
              </Typography>
              <Typography gutterBottom id="rtpSummeryFirstPaymentAmt">
                {formatCurrency(rtp.standingOrder.firstPaymentAmount)}
              </Typography>
            </Box>
            {rtp.standingOrder.finalPaymentAmount && (
              <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" gutterBottom id="rtpSummeryFinalPaymentLabel">
                  Final Payment
                </Typography>
                <Typography gutterBottom id="rtpSummeryFinalPaymentAmy">
                  {formatCurrency(rtp.standingOrder.finalPaymentAmount)}
                </Typography>
              </Box>
            )}
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom id="rtpSummeryFrequencyLabel">
                Frequency
              </Typography>
              <Typography gutterBottom id="rtpSummeryFreq">
                {rtp.standingOrder.frequency}
              </Typography>
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom id="rtpSummeryOccurencesLabel">
                Occurrences
              </Typography>
              <Typography gutterBottom id="rtpSummeryNoOfPayments">
                {rtp.standingOrder.numberOfPayments}
              </Typography>
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom id="rtpSummeryFirstPayDateLabel">
                First payment date
              </Typography>
              <Typography gutterBottom id="rtpSummeryFirstPaymentDate">
                {format(new Date(rtp.standingOrder.firstPaymentDate!), "yyyy-MM-dd")}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
        <Button
          size="small"
          href={`mailto:${senderData?.businessContact?.email ?? senderData?.companyInfo.email}`}
          target="_blank"
          id="rtpSummeryContactButton"
          rel="noopener noreferrer"
          startIcon={<EmailIcon />}>
          Contact {senderData?.businessContact?.name ?? senderData?.name}
        </Button>
        {shouldDisplayDownloadLink && (
          <Button
            size="small"
            onClick={downloadInvoice}
            id="rtpSummeryDownloadInvoiceButton"
            startIcon={<DownloadIcon />}>
            Download Invoice
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default RTPSummary;
