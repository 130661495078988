import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import CONFIG from "@APP/config";
import { ICONS } from "@APP/assets";
import { API } from "@APP/services";
import { SCREEN_PATHS } from "@APP/navigation";
import { configureLogUser, logData } from "@APP/utils";
import { PAYMENT_PROVIDER, RTPStatus } from "@APP/types";
import { PageLayout, Message, RTPSummary } from "@APP/components";
import { useAppDispatch, useAppSelector, useThunkDispatch } from "@APP/redux";
import { /* loadAuthToken, */ loadBanks, clearRtpState, getRtpDetails } from "@APP/redux/actions";

interface QueryParams {
  senderDataKey: string;
  rtpKey: string;
}

const RTP_UNAVAILABLE_STATUSES = [
  RTPStatus.Cancelled,
  RTPStatus.Declined,
  RTPStatus.PaymentReceived,
  RTPStatus.PaymentSent,
];

const RTP_DELETED_STATUSES = [RTPStatus.Deleted];

const LandingScreen: React.FC<RouteComponentProps> = ({ match, history }) => {
  const { t } = useTranslation();
  const dispatchThunk = useThunkDispatch();
  const dispatch = useAppDispatch();

  const rtpState = useAppSelector((state) => state.rtp);
  const banksState = useAppSelector((state) => state.banks);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const params = match.params as QueryParams;
  const { senderDataKey, rtpKey } = params;

  const lastSelectedBank = banksState.selectedBank;

  useEffect(() => {
    const processDataLoading = async () => {
      setLoading(true);
      if (!(senderDataKey && rtpKey)) {
        setErrorMessage("Sorry, we were unable to locate this request.");
        return;
      }
      try {
        /**
         * Disabled due to: https://bankifi.atlassian.net/browse/BK-46
         */
        // await dispatchThunk(loadAuthToken());
        if (CONFIG.PAYMENT_PROVIDER === PAYMENT_PROVIDER.MONEYHUB) {
          await dispatchThunk(loadBanks());
        }
        await dispatchThunk(getRtpDetails(senderDataKey, rtpKey));
        await configureLogUser(rtpState);
      } catch (err: any) {
        const errorData = err?.response?.data;
        if (errorData && errorData.errorCode && errorData.errorCode === 6970) {
          setErrorMessage(
            `This payment request is not currently available, please try again later <br><small>Code - ${errorData.errorCode}</small>`,
          );
        } else {
          setErrorMessage(
            "It looks like there is a problem, please try again in a few moments. If the problem continues please contact the person who sent you the request",
          );
        }
      }
      setLoading(false);
    };
    processDataLoading();
  }, []);

  const handlePayClick = async () => {
    // -- Maverick integration --
    if (CONFIG.PAYMENT_PROVIDER === PAYMENT_PROVIDER.MAVERICK) {
      try {
        await logData("Pay Button Clicked", rtpKey, 0);
        await API.initiateRTP(rtpState.rtpData?.paymentRequestId!);

        history.push(SCREEN_PATHS.MAVERICK_PAYMENT);
      } catch (error) {
        setErrorMessage(
          "Sorry, we are currently unable to take your payment, please try again later",
        );
      }

      return;
    }

    await logData("Pay Button Clicked", rtpKey, 0);
    // -- Moneyhub integration --
    if (lastSelectedBank) {
      history.push(SCREEN_PATHS.INITIATE_PAYMENT.replace(":bankId", lastSelectedBank));
      return;
    }
    history.push(SCREEN_PATHS.BANK_SELECT);
  };

  const handleDeclineClick = async () => {
    await logData("Decline Button Clicked", rtpKey, 0);
    history.push(
      SCREEN_PATHS.DECLINE_RTP.replace(":requestId", rtpState.rtpData?.paymentRequestId ?? ""),
    );
  };

  const handleResetClick = () => {
    dispatch(clearRtpState());
    history.push("/");
  };

  const renderMainContent = () => {
    if (errorMessage) {
      return (
        <Message
          style={{ margin: "auto" }}
          type="error"
          title="Oops, something seems to have gone wrong"
          description={errorMessage}
          buttons={[{ variant: "contained", color: "secondary", onClick: handleResetClick }]}
        />
      );
    }

    if (rtpState.rtp?.status && RTP_UNAVAILABLE_STATUSES.includes(rtpState.rtp.status)) {
      return (
        <Message
          style={{ margin: "auto" }}
          type="info"
          title="Oops, something seems to have gone wrong"
          description={t("Screens.Landing.error1")}
          buttons={[{ variant: "contained", color: "primary", onClick: handleResetClick }]}
        />
      );
    }

    if (rtpState.rtp?.status && RTP_DELETED_STATUSES.includes(rtpState.rtp.status)) {
      return (
        <Message
          style={{ margin: "auto" }}
          type="info"
          title="Oops, something seems to have gone wrong"
          description={t("Screens.Landing.rtpDeletedDescription")}
          buttons={[{ variant: "contained", color: "primary", onClick: handleResetClick }]}
        />
      );
    }

    return (
      <>
        <img src={ICONS.REQUEST_ICON} style={{ width: "6.5vh" }} alt="Request to pay" />
        <Box mt="2.5vh" mb="3vh">
          <Typography align="center" id="landingScreenTypo">
            You have been sent a payment request
          </Typography>
        </Box>
        <RTPSummary rtpState={rtpState} />

        {/* Disabled due to: https://bankifi.atlassian.net/browse/BN-3407 
        {rtpState.rtp?.status === RTPStatus.InProgress ? (
          <Box mt="2.5vh" id="landingScreenInfoMessage">
            <Message
              type="info"
              title="We are attempting to process your payment"
              showIcon={false}
            />
          </Box>
        ) : ( */}
        <>
          <Typography variant="caption" align="center" id="landingScreenLandingText1">
            {t("Screens.Landing.text1")}
          </Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="center" my="1vh" width="100%">
            <Box minWidth="200px" maxWidth="240px" m="2%">
              <Button
                size="large"
                fullWidth
                color="primary"
                id="landingScreenPayButton"
                variant="contained"
                onClick={handlePayClick}>
                Pay
              </Button>
            </Box>
            <Box minWidth="200px" maxWidth="240px" m="2%">
              <Button
                size="large"
                fullWidth
                variant="contained"
                id="landingScreenDecline"
                color="secondary"
                onClick={handleDeclineClick}>
                Decline
              </Button>
            </Box>
          </Box>
        </>
        {/* )} */}
      </>
    );
  };

  return (
    <PageLayout loading={loading}>
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        flexDirection="column"
        id="landingScreenMainBox">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default LandingScreen;
