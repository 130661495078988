import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { CircularProgress, Fade, Typography } from "@mui/material";

import CONFIG from "@APP/config";

import Header from "./Header";
import Footer from "./Footer";

interface Props {
  children: React.ReactElement;
  loading?: boolean;
  loaderText?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    position: "relative",
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: "1.6rem",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  loaderContainer: {
    position: "absolute",
    display: "flex",
    paddingTop: "20vh",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    zIndex: 100,
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    gap: "16px",
  },
  fade: { display: "flex", alignItems: "center", justifyContent: "center" },
  versionLabel: {
    position: "absolute",
    bottom: 5,
    right: 12,
  },
}));

const PageLayout = ({ children, loading = false, loaderText = "" }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="pageLayoutDiv">
      <Header />
      <div className={classes.wrapper} id="pageLayoutWrapper">
        <div className={classes.contentContainer} id="pageLayoutContainer">
          {loading && (
            <div className={classes.loaderContainer}>
              <CircularProgress size="8vh" thickness={3} color="primary" />
              {loaderText ? (
                <Typography component="p" id="rtpCompleteLoaderText">
                  {loaderText}
                </Typography>
              ) : null}
            </div>
          )}
          <div className={classes.content}>
            <main className={classes.mainContent} id="pageLayoutMainContent">
              <Fade timeout={700} in={!loading}>
                <div>{children}</div>
              </Fade>
            </main>
            <Footer />
            <Typography
              variant="caption"
              color="primary"
              className={classes.versionLabel}
              id="versionNumber">{`v ${CONFIG.VERSION}`}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
